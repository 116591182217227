import React, { Component, Fragment } from 'react';
import Loader from '../components/Global/Loader';
import { Animated } from "react-animated-css";
import Navigation from '../components/Navigation/Navigation';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import LoaderSecondary from '../components/Global/LoaderSecondary';
import $ from 'jquery';
import moodle2 from '../helpers/moodle2';
import ListAlumnos from '../components/Extension/ListAlumnos';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

const rowClasses = 'custom-row-class';

export default class routeExtensionId extends Component {

    constructor(props) {
        super(props)
        this.state = {
            producto: null,
            cliente: JSON.parse(sessionStorage.getItem('clienteSelected') ? sessionStorage.getItem('clienteSelected') : null),
            loading:{
                producto: true,
                cursos: true,
            },
            modal:{
                open:       false,
                text:       null,
                error:      false,
            },
            cursos: null,
            idiomaSelected: null,
            ShowHideModalSecciones :false,
            ShowHideModalProducto: false,
            cursoSelected: null,
            expandedRow: null
        }

        this.obtenerProducto = this.obtenerProducto.bind(this);
        this.obtenerCursos = this.obtenerCursos.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleModalSecciones = this.handleModalSecciones.bind(this);
        this.toggleExpandRow = this.toggleExpandRow.bind(this);
        this.isRowExpanded = this.isRowExpanded.bind(this);
        this.expandButtonRenderer = this.expandButtonRenderer.bind(this);
        this.expandRowRenderer = this.expandRowRenderer.bind(this)
    }

    componentDidMount() {
        this.obtenerProducto();
    }

    toggleExpandRow(rowId) {
        this.setState(prevState => ({
            expandedRow: prevState.expandedRow === rowId ? null : rowId
        }));
    }
    isRowExpanded(rowId) {
        return this.state.expandedRow === rowId;
    }
    
    expandRowRenderer(row) {
        return (<>
            {!this.state.loading.cursos ? 
                this.state.cursos[this.state.idiomaSelected][row.id].map((curso)=>{
                    return (
                        <div className='row w-100 pl-4' key={row.id}>
                            <div style={{width: "5%", textAlign: 'center', padding:'0.75rem'}}>
                                <span className="badge badge-secondary">{curso.id}</span>
                            </div>
                            <div className='pl-4' style={{width: "71.5%", padding:'0.75rem'}}>
                                {curso.fullname}
                            </div>
                            <div className='pl-4' style={{width: "23.5%", padding:'0.75rem'}}>
                                <div style={{textAlign: 'center'}}>
                                    <button id={`botonSecciones${curso.id}`} onClick={() => this.handleModalSecciones(curso.id, row.id)} type="button" className="btn btn-secondary botonSecciones">Ver alumnos</button>
                                </div>
                            </div>
                        </div>
                    )
                })
            :
                <LoaderSecondary/>
            }
            </>);
    }
    expandButtonRenderer(cell, row) {
        return (
            <div style={{textAlign: 'center'}}>
                <button id={`botonSecciones${row.id}`} onClick={() => this.toggleExpandRow(row.id)} type="button" className="btn btn-primary botonSecciones">{!this.isRowExpanded(row.id) ? 'Ver cursos' : 'Cerrar'}</button>
            </div>)
    }

    obtenerProducto() {
        try{
            var myHeaders = new Headers();
            myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

            var requestOptions = {
                method: 'GET',
                headers: myHeaders,
                redirect: 'follow'
            };

            fetch(window.$UrlOctavio + "/productos/" + this.props.match.params.id, requestOptions)
                .then(response => response.json())
                .then(result => {
                    if(result.fechas_inicio == null){
                        result.fechas_inicio = [new Date(2023,0,10)];
                    }else{
                        let fechas = [];
                        result.fechas_inicio.forEach(fecha=>{
                            fechas.push(new Date(2023,0,fecha));
                        })
                        result.fechas_inicio = fechas;
                    }
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            producto: false
                        },
                        producto: result,
                        idiomaSelected: Object.keys(result.cursos)[0],
                    })
                    this.obtenerCursos();
                })
                .catch(error => {
                    this.setState({
                        ...this.state,
                        loading:{
                            ...this.state.loading,
                            producto: false
                        },
                        modal:{
                            ...this.state.modal,
                            open: true,
                            error: true,
                            text: 'Ocurrio un error al obtener el producto'
                        }
                    })
                    console.log('error', error)
                })
        }catch(error){
            console.log(error);
        }
    }

    async obtenerCursos(){
        try {
            for(let idioma of Object.keys(this.state.producto.idsMoodle2)){

                let cursosByCategory = {};

                for(let categoria of this.state.producto.idsMoodle2[idioma]){

                    cursosByCategory[categoria] = [];
                    let response = await moodle2.getCoursesByCategory(categoria,this.state.cliente);
                    if(!!response){
                        cursosByCategory[categoria] = response.courses;
                        await cursosByCategory[categoria].forEach(curso => {
                            curso.estado = 1;
                            curso.mostrar = true;
                            curso.fechaOriginal = curso.startdate;
                            curso.startdate = new Date(curso.startdate*1000)
                        })
                    }else {
                        this.setState({
                            ...this.state,
                            modal:{
                                ...this.state.modal,
                                open: true,
                                error: true,
                                text: 'Ocurrio un error al obtener los cursos del producto'
                            }
                        })
                    }
                    await cursosByCategory[categoria].sort((a,b) => +new Date(a.startdate) - +new Date(b.startdate))
                }
                this.setState({
                    ...this.state,
                    cursos:{
                        ...this.state.cursos,
                        [idioma]:cursosByCategory
                    },
                })
            }
            this.setState({...this.state,loading:{...this.state.loading,cursos: false}})
        }catch(error){
            this.setState({
                ...this.state,
                modal:{
                    ...this.state.modal,
                    open: true,
                    error: true,
                    text: 'Ocurrio un error al obtener los cursos del producto'
                }
            })
            console.log(error);
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            }
        }))
        this.obtenerCursos();
    }

    handleModalSecciones(curso, categoria){
        this.setState(prevState => ({
            ShowHideModalSecciones: !prevState.ShowHideModalSecciones,
            cursoSelected: {curso: curso, categoria: categoria}
        }))
    }

    handleCambioIdioma(idioma){
        this.setState({
            ...this.state,
            idiomaSelected: idioma,
        })
    }

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Calendarios</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {this.state.modal.text ?
                            <div>
                                {this.state.modal.error === true ?
                                    <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    :
                                    <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                }
                                <p>{this.state.modal.text}</p>
                            </div>
                        : <></>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    modalSecciones(){
        if(!!this.state.ShowHideModalSecciones){
            return(
                <ListAlumnos handleFuncion={this.handleModalSecciones} cursoNombre={this.state.cursos[this.state.idiomaSelected][this.state.expandedRow].filter(curso => curso.id == this.state.cursoSelected.curso)[0].fullname} curso={this.state.cursoSelected.curso} cliente={this.state.cliente} cap={this.state.categoriaCap === this.state.cursoSelected.categoria}/>
                )
        }
    }

    renderProducto() {
        const columns = [
            {
                dataField: 'id',
                text: 'Id',
                align: 'center',
                headerClasses: 'border-0',
                uniqueData: "id",
                headerStyle: () => {
                    return { width: "5%", textAlign: 'center' };
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.id}</span>
                    );
                }
            },
            {
                dataField: 'nombre',
                text: 'Nombre',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "55%" };
                },
                uniqueData: "nombre",
            },
            {
                dataField: 'acciones',
                text: 'Acciones',
                headerClasses: 'border-0',
                uniqueData: "acciones",
                headerStyle: () => {
                    return { width: "20%", textAlign: 'center' };
                },
                formatter: (cell, row, rowIndex) =>{
                    const isLastRow = rowIndex === this.state.producto.cursos['es'].length - 1;
                    if(this.state.producto.tiene_cap && isLastRow){
                        this.setState({
                            ...this.state,
                            categoriaCap: row.id
                        })
                    }
                    return this.expandButtonRenderer(cell,row)
                }
        
            }
        ];
        
        return (
            <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                            <div className="box-white">
                                <div className="col-12 d-flex align-items-center justify-content-between">
                                    <h6 className="m-0">Categorías</h6>
                                </div>
                                <hr />
                                {this.state.producto.cursos ? <>
                                    <nav>
                                        <div className="nav nav-tabs" id="tabIdiomas" role="tablist">
                                            {Object.keys(this.state.producto.cursos).map((idioma, i) => {
                                                return(
                                                idioma === this.state.idiomaSelected ?
                                                    <a key={i} className="nav-link active" onClick={() => this.handleCambioIdioma(idioma)} data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>
                                                    :
                                                    <a key={i} className="nav-link" onClick={() => this.handleCambioIdioma(idioma)} data-toggle="tab" href={`#id${i}`} role="tab" aria-selected="true">Idioma: {idioma}</a>
                                                )
                                            })}
                                        </div>
                                    </nav>
                                    <div className="tab-content mt-3">
                                        {Object.keys(this.state.producto.cursos).map((idioma, i) => (
                                            i === 0 ?
                                                <div key={i} className="tab-pane fade active show" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                data={this.state.producto.cursos[idioma]}
                                                                columns={columns}
                                                            >
                                                                {
                                                                    props => (
                                                                        <BootstrapTable
                                                                            striped
                                                                            key={this.state.expandedRow}
                                                                            hover
                                                                            bordered={false}
                                                                            headerClasses="header-class"
                                                                            {...props.baseProps}
                                                                            rowClasses={rowClasses}
                                                                            expandRow={{
                                                                                renderer: this.expandRowRenderer,
                                                                                expanded: [this.state.expandedRow],
                                                                                expandByColumnOnly: true,
                                                                                onlyOneExpanding: true,
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Animated>
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="tab-pane fade" id={`id${i}`} role="tabpanel">
                                                    <div className="list-group">
                                                        <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                data={this.state.producto.cursos[idioma]}
                                                                columns={columns}
                                                            >
                                                                {
                                                                    props => (
                                                                        <BootstrapTable
                                                                            striped
                                                                            key={this.state.expandedRow}
                                                                            hover
                                                                            bordered={false}
                                                                            headerClasses="header-class"
                                                                            {...props.baseProps}
                                                                            rowClasses={rowClasses}
                                                                            expandRow={{
                                                                                renderer: this.expandRowRenderer,
                                                                                expanded: [this.state.expandedRow],
                                                                                expandByColumnOnly: true,
                                                                                onlyOneExpanding: true,
                                                                            }}
                                                                        />
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Animated>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Animated>
        )
        
    }

    route(){
        return (
            <>
                <div className="bg-light pt-3">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <label className="navigation-breadcrumb">Inicio &gt; Extension</label>
                                <div className="d-flex align-items-center">
                                    <Link to={"/extension"} className="btn btn-transparent mr-3 border"><i className="fas fa-arrow-left"></i></Link>
                                    <h5 className="m-0">Producto / {!!this.state.loading.producto ? <LoaderSecondary></LoaderSecondary> : this.state.producto.nombre}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="navbarstiky bg-white mb-4 pb-3 pt-3">
                    <div className="container">
                        <div className="d-flex flex-column flex-xl-row flex-lg-row flex-md-row flex-sm-row justify-content-between">
                        </div>
                    </div>
                </div>
            </>
        )
    }

    render() {
        return (<>
            {this.state.cliente !== undefined && this.state.cliente !== null ? <></> : <Redirect from="/" to="/extension"/>}
            {this.state.modal.open ? this.modalValidacion() : <></>}
            {this.modalSecciones()}
            <Navigation />
            {this.route()}
            {this.state.loading.producto === true ? <Loader/> : this.renderProducto()}
            {/* {console.log(this.state)} */}
        </>)
    }
}

$('#tabIdiomas a').on('click', function (event) {
    event.preventDefault()
    $(this).tab('show')
})
