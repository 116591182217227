// Generic 
import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import store from '../../store';
import $ from 'jquery';

// Components
import AlertForm from '../Global/AlertForm';

// Images
import bg1 from '../../assets/images/login-bg-1.jpg';
import bg2 from '../../assets/images/login-bg-2.jpg';
import bg3 from '../../assets/images/login-bg-3.jpg';
import bg4 from '../../assets/images/login-bg-4.jpg';
import logo from './../../assets/images/olivia.png';

// Css
import './LoginComponent.css';



class LoginComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            validation: "needs-validation",
            buttonDisable: false,
            alert: false,
            error_code: "",
            error_body: "",
            login: false,
            data: {
                usuario_email: '',
                usuario_contrasenia: ''
            }
        }
        this.handleInput = this.handleInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleValidation = this.handleValidation.bind(this);
        this.sendForm = this.sendForm.bind(this);
    }

    componentDidMount() {
        $('.alert-error').hide();

        var count = 0;
        var images = [bg1, bg2, bg3, bg4];
        var image = $(".fader");

        image.css("background-image", "url(" + images[count] + ")");

        setInterval(function () {
            image.fadeOut(500, function () {
                image.css("background-image", "url(" + images[count++] + ")");
                image.fadeIn(500);
            });
            if (count === images.length) {
                count = 0;
            }
        }, 5000);
    }

    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            }
        })
        // console.log(this.state.data)
    }

    handleValidation() {
        var emailRegex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
        if (!emailRegex.test(this.state.data.usuario_email)) {
            return false;
        } else {
            if (!this.state.data.usuario_email) {
                $('.alert-error').show();
                return false;
            } else if (!this.state.data.usuario_contrasenia) {

            }
            else {
                return true;
            }
        }
    }

    handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            validation: "needs-validation was-validated"
        });
        if (this.handleValidation() === true) {
            this.sendForm();
        };
    }

    obtenerClientes() {
        var myHeaders = new Headers();
        myHeaders.append("Auth-Token", "zBKhfY9jXOUNVunScyIBltJJa3W1vg?HXsXPK5T1NCq1G!lXKN1ve9QOm25zIInZAQNgAK2VS7kTj7370NgqPg8w3DiQDb8AgtCCg6YNhU4wo45K!wEnOjPSnOtC8Rs");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch("https://octaviodev.capabiliaserver.com/api/v1/clientes", requestOptions)
            .then(response => response.json())
            .then(result => {
                // console.log(result)
                var clientes = [];
                for (let i = 0; i < result.length; i++) {
                    clientes.push({
                        'id': result[i].id,
                        'dominio': result[i].dominio,
                        'path': result[i].path,
                        'path_moodle': result[i].path_moodle,
                        'moodleToken': result[i].moodleToken,
                        'path_moodle2': result[i].path_moodle2,
                        'moodleToken': result[i].moodleToken,
                        'moodleToken2': result[i].moodleToken2,
                        'moodle2TZ': result[i].moodle2TZ
                    })
                }
                localStorage.setItem('clientes', JSON.stringify(clientes))
            })
            .catch(error => console.log('error', error));
    }

    async sendForm() {
        this.setState({
            buttonDisable: true
        });

        this.obtenerClientes()

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        var raw = {
            "usuario_email": this.state.data.usuario_email,
            "usuario_contrasenia": this.state.data.usuario_contrasenia
        };

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: JSON.stringify(raw),
            redirect: 'follow'
        };

        fetch(window.$UrlApiDev + "/login", requestOptions)
            .then(response => response.json())
            .then(result => {
                localStorage.setItem('token', result.body.token);
                localStorage.setItem('usuario', result.body.usuario_email)
                localStorage.setItem('usuarioId', result.body.id)

                store.dispatch({
                    type: "TOKEN",
                    token: result.body.token
                })

                if (result.body.token) {
                    this.setState({
                        alert: false,
                        login: true
                    })
                }
                this.setState({
                    alert: true,
                    error_code: result.status,
                    error_body: result.body,
                    buttonDisable: false,
                })

            })
            .catch(error => console.log('error', error));

    }

    buttonsAction() {
        if (this.state.buttonDisable === false) {
            return (
                <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.handleSubmit}>
                    Iniciar Sesión
                </button>
            )
        } else {
            return (
                <div className="right-footer d-flex justify-content-center align-items-stretch">
                    <button disabled={this.state.buttonDisable} className="btn btn-primary" onClick={() => this.handleSubmit}>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                        <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                        <span className="sr-only">Loading...</span>
                         Validando...
                    </button>
                </div>
            )
        }
    }



    render() {
        if (this.state.login === true) {
            return (
                <Redirect to="/escritorio" />
            )
        } else {
            return (
                <section className="login">

                    <div className="box-login">
                        <img className="mb-5" src={logo} alt="" />
                        <h1>
                            Inicia Sesión
                        </h1>
                        <p className="global-text-1">Completa el formulario con tus datos para iniciar sesión</p>
                        <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                            <div className="form-group">
                                <label htmlFor="usuario_email">Email</label>
                                <input
                                    type="email"
                                    className="form-control"
                                    id="usuario_email"
                                    name="usuario_email"
                                    placeholder="Ej: usuario@capabilia.org"
                                    onChange={this.handleInput}
                                    required
                                />
                                <div className="invalid-feedback">Ingresa tu email</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="form-group">
                                <label htmlFor="usuario_contrasenia">Contraseña</label>
                                <input
                                    type="password"
                                    className="form-control"
                                    id="usuario_contrasenia"
                                    name="usuario_contrasenia"
                                    placeholder="******"
                                    onChange={this.handleInput}
                                    required
                                />
                                <div className="invalid-feedback">Ingresa tu contraseña</div>
                                <div className="valid-feedback">Excelente!</div>
                            </div>
                            <div className="text-right">
                                {this.buttonsAction()}
                                <AlertForm show={this.state.alert} code={this.state.error_code} body={this.state.error_body}></AlertForm>
                            </div>
                        </form>
                    </div>

                    <div className="fader">

                    </div>

                </section>
            )
        }
    }
}

export default LoginComponent;