import React, { Component } from 'react';
import IconLarge from '../Global/IconLarge';
import LoaderSecondary from '../Global/LoaderSecondary';
import { Animated } from "react-animated-css";
import { Spinner } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import moodle2 from '../../helpers/moodle2'

let seleccionados = [];

export default class ExtenderCursado extends Component {

    constructor(props) {
        super(props);
        this.state = {
            buttonDisable: false,
            validation: "needs-validation",
            data: {},
            modal:{
                open:       false,
                text:       '',
                error:      false,
            },
        }
        this.handleClose = this.handleClose.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleClose() {
        this.props.handleFuncion()
    }

    handleInput(e) {
        const { value, name } = e.target;
        this.setState({
            data: {
                ...this.state.data,
                [name]: value
            },
        })
    }

    handleValidation() {
        this.setState({
            validation: "needs-validation was-validated",
        });
        if (!this.state.data.fecha_fin) {
            return false;
        } else {
            return true;
        }
    }

    async handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        if (this.handleValidation()) {
            this.setState({
                buttonDisable: true,
            })
            try{
                let erroresMails= [];
                for(let alumno of seleccionados){
                    alumno.status = 'loading';
                    this.forceUpdate();
                    let response = await moodle2.enrollUserToCourse(this.props.cursoId,alumno.id,this.props.cliente,this.props.startDate,new Date(`${this.state.data.fecha_fin} 00:00:00`).getTime()/1000)

                    if(response.status == 200){
                        alumno.status = 'success';
                        this.forceUpdate();
                    }else{
                        alumno.status = 'error';
                        this.forceUpdate();
                        erroresMails.push(alumno.email)
                        this.setState({
                            ...this.state,
                            modal:{
                                open:       true,
                                text:       `Error en los siguientes alumnos: ${this.state.errores.map(error=>error)}`,
                                error:      true,
                            },
                        })
                    }
                }
                this.setState({
                    ...this.state,
                    errores: erroresMails
                })
            }catch(e){
                console.log(e);
                this.setState({
                    ...this.state,
                    modal:{
                        open:       true,
                        text:       `Error al procesar la consulta`,
                        error:      true,
                    },
                })
            }
            this.setState({
                ...this.state,
                modal:{
                    open:       true,
                    text:       `Cursado extendido correctamente`,
                    error:      false,
                },
                buttonDisable: false
            });
            return false;
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            },
        }))
        this.handleClose()
    }

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Extension de cursado</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.props.close}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {this.state.modal.text ?
                            <div>
                                {this.state.modal.error === true ?
                                    <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    :
                                    <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                }
                                <p>{this.state.modal.text}</p>
                            </div>
                        : <></>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.props.close}>
                            Cerrar
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    render() {
        const columns = [
            {
                dataField: 'id',
                sort: true,
                text: 'Id',
                align: 'center',
                headerClasses: 'border-0',
                uniqueData: "id",
                style: {
                    width: "10%"
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.estado !== 0 ? row.id : 'N/a'}</span>
                    );
                }
            },
            {
                dataField: 'fullname',
                sort: true,
                text: 'Nombre',
                headerClasses: 'border-0',
                style: {
                    width: "40%",
                    wordBreak: "break-word"
                },
                uniqueData: "fullname",
            },
            {
                dataField: 'email',
                sort: true,
                text: 'Email',
                headerClasses: 'border-0',
                style: {
                    width: "40%",
                    wordBreak: "break-word"
                },
                uniqueData: "email"
            },
            {
                dataField: 'status',
                sort: true,
                
                text: 'Estado',
                headerClasses: 'border-0',
                style: {
                    width: "10%"
                },
                uniqueData: "status",
                formatter: (cell, row) =>{
                    switch (row.status) {
                        case 'success':
                            return <i className='fa fa-check-circle' style={{ color: 'green' }} title="Success" />;
                        case 'error':
                            return <i className='fa fa-times-circle' style={{ color: 'red' }} title="Error" />;
                        case 'loading':
                            return <Spinner animation="border" size="sm" variant="primary" />;
                        case 'pending':
                            return <i className='fa fa-exclamation-circle' style={{ color: '#007bff' }} title="Pending" />;
                    }
                }
            },
        ];
        seleccionados = this.props.alumnos.filter(alumno=>this.props.IdsSelected.includes(alumno.id));

        if (this.props.show) {
            let minDate = this.props.cap ? 4 : 1;
            return (<>
                {this.state.modal.open ? this.modalValidacion() : <></>}
                <div className="right-overlay zindextop">
                    <div className="right-content bg-white shadow-lg">
                        <div className="right-header">
                            <div className="row d-flex justify-content-between align-items-center">
                                <div>
                                    <h6>Extender cursado</h6>
                                </div>
                                <div>
                                    <button className="btn btn-close" onClick={() => this.handleClose()}>
                                        <i className="fa fa-times"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="right-body bg-white">
                            <div className="row">
                                <div className="col-lg-12 modal-extender-cursado">
                                    <div className='d-flex justify-content-between mb-3'>
                                        <h6>Vista previa</h6>
                                        <span>{new Date(this.props.startDate*1000).toISOString().split('T')[0]}</span>
                                    </div>
                                    {this.state.estado ? false :<>

                                            <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                                <ToolkitProvider
                                                    keyField='id'
                                                    data={seleccionados}
                                                    columns={columns}
                                                >
                                                    {
                                                        props => (
                                                            <BootstrapTable
                                                                bordered={true}
                                                                pagination={paginationFactory()}
                                                                headerClasses="hide-header"
                                                                {...props.baseProps}
                                                            />
                                                        )
                                                    }
                                                </ToolkitProvider>
                                            </Animated>
                                            <form onSubmit={this.handleSubmit} className={this.state.validation} noValidate>
                                                <div className="form-group mt-5">
                                                    <label htmlFor="fecha_fin">Fecha de fin</label>
                                                    <input id="fecha_fin" type="date" className="form-control" name="fecha_fin" onChange={this.handleInput} min={new Date(new Date(this.props.startDate * 1000).setMonth(new Date(this.props.startDate * 1000).getMonth() + minDate)).toISOString().split('T')[0]} max={new Date(new Date(this.props.startDate * 1000).setMonth(new Date(this.props.startDate * 1000).getMonth() + minDate +1 )).toISOString().split('T')[0]} required></input>
                                                    <div className="invalid-feedback">Ingresa la fecha de inicio</div>
                                                    <div className="valid-feedback">Excelente!</div>
                                                </div>

                                            </form>
                                        </>
                                    }

                                    {this.state.estado === 400 &&
                                        <div>
                                            <IconLarge icon="fa-close" size="4" color="text-danger" animation="shake" />
                                            <p><b>Error</b></p>
                                            <p>Estado: {this.state.estado} <br></br></p>
                                        </div>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="right-footer d-flex justify-content-center align-items-center">
                            <button disabled={this.state.buttonDisable} className="btn btn-primary mr-2" onClick={(e) => this.handleSubmit(e)}>
                                {this.state.buttonDisable ? <LoaderSecondary/> : 'Aceptar'}
                            </button>
                            <button disabled={this.state.buttonDisable} className="btn btn-secondary" onClick={() => this.handleClose()}>
                                Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </>);
        } else {
            return false;
        }
    }
}
