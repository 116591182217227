import React, { Component, Fragment } from 'react';
import { Animated } from "react-animated-css";
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ExtenderCursado from '../Extension/ExtenderCursado';
import LoaderSecondary from '../Global/LoaderSecondary';
import Loader from '../Global/Loader';
import moodle2 from '../../helpers/moodle2';
import $ from 'jquery';

export default class ListSeccionesMoodle extends Component {

    constructor(props) {
        super(props)
        this.state = {
            curso: null,
            date : null,
            overlayShowSeccionManual: false,
            ShowHideModalEditarSeccion: false,
            loading: true,
            alumnos: [],
            fechasInicio: [],
            selectedRows:[],
            fechaSelected: null,
            modal:{
                open:       false,
                text:       '',
                error:      false,
                loading:    false
            },
        }
        this.overlayShowHideSeccionManual = this.overlayShowHideSeccionManual.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.handleModal = this.handleModal.bind(this);
        this.obtenerAlumnos = this.obtenerAlumnos.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleSelectAll = this.handleSelectAll.bind(this);
    }

    handleClose() {
        this.props.handleFuncion()
    }

    overlayShowHideSeccionManual() {
        this.setState(prevState => ({
            ...this.state,
            overlayShowSeccionManual: !prevState.overlayShowSeccionManual
        }));
    }

    componentDidMount() {
        this.obtenerAlumnos();
    }

    async obtenerAlumnos(){
        let response = await moodle2.getUsersByCourse(this.props.curso,this.props.cliente);
        if(!!response){
            let fechas = response.reduce((result,item) =>{
                const group = result.find(g=>g === item.enrolmenttimestart)
                if(!group){
                    result.push(item.enrolmenttimestart)
                }
                return result
            },[]);
            for(let alumno of response){
                alumno.status = 'pending';
            }
            this.setState({
                ...this.state,
                loading:false,
                alumnos: response,
                fechasInicio: fechas,
                fechaSelected: fechas.filter(fecha=>fecha !== 0)[0]
            })
        }else {
            this.setState({
                ...this.state,
                modal:{
                    ...this.state.modal,
                    open: true,
                    error: true,
                    text: 'Ocurrio un error al obtener los alumnos del curso'
                }
            })
        }
    }

    handleModal() {
        this.setState(prevState => ({
            modal:{
                ...this.state.modal,
                open: !prevState.modal.open,
            },
        }))
        this.handleClose()
    }

    handleSelect(row, isSelect){
        const { selectedRows } = this.state;
        if (isSelect) {
            this.setState({
                selectedRows: [...selectedRows, row.id] // Add selected row ID
            });
        } else {
            this.setState({
                selectedRows: selectedRows.filter(id => id !== row.id) // Remove unselected row ID
            });
        }
    };

    handleSelectAll(isSelect, rows, timestamp) {
        if (isSelect) {
            this.setState({
                selectedRows: rows.map(row => row.id) // Select all row IDs
            });
        } else {
            this.setState({
                selectedRows: [], // Deselect all
            });
            if(typeof timestamp === 'number' && !isNaN(timestamp)){
                this.setState({
                    ...this.state,
                    fechaSelected: timestamp
                })
            }
        }
    };

    modalValidacion() {
        return (
            <div className="custom-modal" style={{zIndex: '100000'}}>
                <div className="custom-modal-content">

                    <div className="custom-modal-header">
                        <div className="row d-flex justify-content-between align-items-center">
                            <div>
                                <h6>Calendarios</h6>
                            </div>
                            <div>
                                <button className="btn btn-close" onClick={this.handleModal}>
                                    <i className="fa fa-times"></i>
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className="right-body bg-white text-center">
                        {!this.state.modal.loading ?
                            this.state.modal.text ?
                                <div>
                                    {this.state.modal.error === true ?
                                        <i className="fa fa-times text-danger fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                        :
                                        <i className="fa fa-check text-sussess fa-5x ml-auto mr-auto mb-2 mt-1 d-block"></i>
                                    }
                                    <p>{this.state.modal.text}</p>
                                </div>
                            : <></> 
                        :
                            <Loader/>}
                    </div>
                    <div className="right-footer d-flex justify-content-center align-items-stretch">
                        <button className="btn btn-secondary mr-2" onClick={this.handleModal}>
                            {this.state.modal.loading ? <LoaderSecondary/> : 'Cerrar'}
                        </button>
                    </div>
                </div>
            </div>
        )
    }

    handleInput(e) {
        const { value } = e.target;
        this.setState({
            ...this.state,
            date: value
        })
    }
    
    render() {
        const columns = [
            {
                dataField: 'id',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
                    return null;
                },
                text: 'Id',
                align: 'center',
                headerClasses: 'border-0',
                uniqueData: "id",
                headerStyle: () => {
                    return { width: "5%" };
                },
                formatter: (cellContent, row) => {
                    return (
                        <span className="badge badge-primary">{row.estado !== 0 ? row.id : 'N/a'}</span>
                    );
                }
            },
            {
                dataField: 'fullname',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Nombre',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "25%" };
                },
                uniqueData: "fullname",
            },
            {
                dataField: 'email',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Email',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "25%" };
                },
                uniqueData: "email"
            },
            {
                dataField: 'roles',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort"></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up"></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down"></i>);
                    return null;
                },
                text: 'Rol',
                headerClasses: 'border-0',
                headerStyle: () => {
                    return { width: "15%" };
                },
                uniqueData: "roles",
                formatter: (cellContent, row) => {
                    return (<>
                        <p>{row.roles[0].shortname}</p>
                        </>);
                }
            },
            {
                dataField: 'enrolmenttimestart',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
                    return null;
                },
                text: 'Fecha inicio',
                headerClasses: 'border-0',
                uniqueData: "enrolmenttimestart",
                headerStyle: () => {
                    return { width: "15%" };
                },
                style: {
                    width: "15%",
                    wordBreak: "break-word"
                },
                formatter: (cellContent, row) => {
                    return (
                        <span>{new Date(row.enrolmenttimestart*1000).toLocaleDateString()}</span>
                    );
                }
            },
            {
                dataField: 'enrolmenttimeend',
                sort: true,
                sortCaret: (order, column) => {
                    if (!order) return (<i className="ml-2 fas fa-sort" key={column.id}></i>);
                    else if (order === 'asc') return (<i className="ml-2 fas fa-sort-up" key={column.id}></i>);
                    else if (order === 'desc') return (<i className="ml-2 fas fa-sort-down" key={column.id}></i>);
                    return null;
                },
                text: 'Fecha Fin',
                headerClasses: 'border-0',
                uniqueData: "enrolmenttimeend",
                headerStyle: () => {
                    return { width: "15%" };
                },
                style: {
                    width: "15%",
                    wordBreak: "break-word"
                },
                formatter: (cellContent, row) => {
                    return (
                        <span>{new Date(row.enrolmenttimeend*1000).toLocaleDateString()}</span>
                    );
                }
            }
        ];
        const rowClasses = 'custom-row-class';
        
        const MySearch = (props) => {
            let input;
            const handleClick = () => {
                props.onSearch(input.value);
            };
            return (
                <input
                    id="searchCustom"
                    className="form-control input-search custom-big-search"
                    ref={n => input = n}
                    type="text"
                    placeholder="Buscar"
                    onChange={handleClick}
                />
            );
        };
            return (
                <div>
                    {this.state.modal.open ? this.modalValidacion() : <></>}
                    {/* {this.modalEditarSeccion()} */}
                    <ExtenderCursado cursoId={this.props.curso} cursoNombre={this.props.cursoNombre} handleFuncion={this.overlayShowHideSeccionManual} close={this.handleModal} show={this.state.overlayShowSeccionManual} closePadre={this.props.handleFuncion} startDate={this.state.fechaSelected} alumnos={this.state.alumnos} IdsSelected={this.state.selectedRows} cliente={this.props.cliente} cap={this.props.cap}/>

                    <div className="my-modal">

                        <div className="my-modal-content max-w-90">
                            <div className="my-modal-header">
                                <div className="row d-flex justify-content-between align-items-center">
                                    <h6>{this.props.cursoNombre}</h6>
                                    <button onClick={() => this.handleClose()} className="btn btn-close"><i className="fa fa-times"></i></button>
                                </div>
                            </div>
                            <div className="my-modal-body text-left min-h-50 pt-0">
                                {!this.state.loading ? <>
                                    <nav>
                                        <div className="nav nav-tabs" id="tabFechas" role="tablist">
                                            {this.state.fechasInicio.filter(fecha=>fecha !== 0).map((timestamp,i) => {
                                                return(
                                                i === 0 ?
                                                    <a key={i} className="nav-link active" data-toggle="tab" onClick={() => this.handleSelectAll(false,{},timestamp)} href={`#id${timestamp}`} role="tab" aria-selected="true">Fecha: {new Date(timestamp*1000).toISOString().split('T')[0]}</a>
                                                    :
                                                    <a key={i} className="nav-link" data-toggle="tab" onClick={() => this.handleSelectAll(false,{},timestamp)} href={`#id${timestamp}`} role="tab" aria-selected="true">Fecha: {new Date(timestamp*1000).toISOString().split('T')[0]}</a>
                                                )
                                            })}
                                        </div>
                                    </nav>
                                    <div className="tab-content mt-3">
                                        {this.state.fechasInicio.filter(fecha=>fecha !== 0).map((timestamp, i) => (
                                            i === 0 ?
                                                <div key={i} className="tab-pane fade active show" id={`id${timestamp}`} role="tabpanel">
                                                    <div className="list-group">
                                                        <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                data={this.state.alumnos.filter(alumno=>alumno.enrolmenttimestart === timestamp)}
                                                                columns={columns}
                                                                search={<MySearch />}
                                                            >
                                                                {
                                                                    props => (
                                                                        <Fragment>
                                                                            <div className="search-group d-flex align-items-center justify-content-start pt-3 pb-2 sticky-top bg-white" key={4898794654654}>
                                                                                <label htmlFor="searchCustom" className="customlabelSearch">Buscar alumnos</label>
                                                                                <MySearch
                                                                                    {...props.searchProps}
                                                                                />
                                                                            </div>
                                                                            <BootstrapTable
                                                                                striped
                                                                                hover
                                                                                bordered={false}
                                                                                pagination={paginationFactory()}
                                                                                paginationSize={50}
                                                                                headerClasses="header-class"
                                                                                {...props.baseProps}
                                                                                rowClasses={rowClasses}
                                                                                selectRow= {{
                                                                                    mode: 'checkbox',
                                                                                    clickToSelect: true,
                                                                                    selected: this.state.selectedRows,
                                                                                    onSelect: this.handleSelect,
                                                                                    onSelectAll: this.handleSelectAll
                                                                                }}
                                                                            />
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Animated>
                                                    </div>
                                                </div>
                                                :
                                                <div key={i} className="tab-pane fade" id={`id${timestamp}`} role="tabpanel">
                                                    <div className="list-group">
                                                        <Animated animationIn="slideInUp" animationOut="fadeOut" animationInDuration={380} isVisible={true}>
                                                            <ToolkitProvider
                                                                keyField='id'
                                                                data={this.state.alumnos.filter(alumno=>alumno.enrolmenttimestart === timestamp)}
                                                                columns={columns}
                                                                search={<MySearch />}
                                                            >
                                                                {
                                                                    props => (
                                                                        <Fragment>
                                                                            <div className="search-group d-flex align-items-center justify-content-start pt-3 pb-2 sticky-top bg-white" key={4898794654654}>
                                                                                <label htmlFor="searchCustom" className="customlabelSearch">Buscar alumnos</label>
                                                                                <MySearch
                                                                                    {...props.searchProps}
                                                                                />
                                                                            </div>
                                                                            <BootstrapTable
                                                                                striped
                                                                                hover
                                                                                bordered={false}
                                                                                pagination={paginationFactory()}
                                                                                paginationSize={50}
                                                                                headerClasses="header-class"
                                                                                {...props.baseProps}
                                                                                rowClasses={rowClasses}
                                                                                selectRow= {{
                                                                                    mode: 'checkbox',
                                                                                    clickToSelect: true,
                                                                                    selected: this.state.selectedRows,
                                                                                    onSelect: this.handleSelect,
                                                                                    onSelectAll: this.handleSelectAll
                                                                                }}
                                                                            />
                                                                        </Fragment>
                                                                    )
                                                                }
                                                            </ToolkitProvider>
                                                        </Animated>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </>
                                    :
                                    <></>
                                }
                            </div>
                            <div className="my-modal-footer d-flex justify-content-center align-items-stretch">
                                <button className="btn btn-primary" onClick={() => this.overlayShowHideSeccionManual()}>Extender cursado</button>
                            </div>
                        </div>
                    </div>
                </div>
            )

    }
}

$('#tabFechas a').on('click', function (event) {
    event.preventDefault()
    $(this).tab('show')
})

